import React from 'react';
import './Footer.css'
import JD from '../../image/jAndD2.webp';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

const Footer = () => {
    const theme = createTheme({
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily: "'Montserrat', sans-serif",
              color: "rgb(197, 165, 37)",
              lineHeight: 2,
              letterSpacing: 3.2,
              fontSize: "1.5vw",
              "@media (max-width: 600px)": {
                fontSize: "3.3vw",
                letterSpacing: 1.5,
              },
              "@media (max-width: 400px)": {
                fontSize: "3.3vw",
                letterSpacing: 1,
              },
            },
          },
        },
      },
    });

    return (
      <footer className="App-footer">
        {/* <img className="jd-footer-logo" src={JD} /> */}
        <ThemeProvider theme={theme}>
          <ButtonGroup variant="text" aria-label="Basic button group">
            <Button
              href="https://github.com/codereyes-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github 
            </Button>
            <Button
              href="https://www.linkedin.com/in/julio-daniel-6356441b3/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn 
            </Button>
            <Button href="/Contact">Contact </Button>
          </ButtonGroup>
        </ThemeProvider>
      </footer>
    );
}

export default Footer;