import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavbarBrand } from "react-bootstrap";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";

import "./NavBar.css";
import NavHamburger from "./NavHamburger";
import jAndD2 from "../../image/jAndD2.webp";
import useScreenSize from './useScreenSize'
import { styled } from "@mui/system";

import { createTheme, ThemeProvider } from "@mui/material/styles";


const NaviBar = () => {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useScreenSize('(max-width: 768px)');
  
  const toggleHamburger = () => {
    setExpanded(!expanded);
  };

  const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    fontSize: "30px",
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
    color: "rgb(29, 29, 29)",
    textDecoration: "none",
  }));

  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          body1: {
            fontSize: "6.9vw",
          },
        },
      },
    },
  });

  const NavbarLinks = () => (
    <>
      <div className={`navbar-nav ${expanded ? "open" : ""}`}>
        <Nav.Link data-testid="nav-link1" className="nav-link" as={Link} to="/">
          <div className="nav-button">./001 Portfolio</div>
        </Nav.Link>
        <Nav.Link
          data-testid="nav-link1"
          className="nav-link"
          as={Link}
          to="/About"
        >
          <div className="nav-button">./002 About</div>
        </Nav.Link>
        <Nav.Link
          data-testid="nav-link1"
          className="nav-link"
          as={Link}
          to="/Contact"
        >
          <div className="nav-button">./003 Contact</div>
        </Nav.Link>
      </div>
    </>
  );

  const DrawerLinks = () => (
    <ThemeProvider theme={theme}>
      <div className={`navbar-nav ${expanded ? "open" : ""}`}>
        <Drawer
          className="drawerPaper"
          anchor="right"
          data-testid="toggles"
          open={expanded}
          onClose={toggleHamburger}
          sx={{
            "& .MuiDrawer-paper": {
              textShadow: "0 1.6px 2px rgba(0, 0, 0, 0.6)",
              background:
                "linear-gradient(90deg, rgba(206, 204, 137, 0.971) 6%,#debd18 78%)",
              width: "17%",
              alignItems: "center",
              paddingTop: "65%",
              opacity: 0.94,
              borderRadius: "260px 0 0 0",
              "& .MuiTypography-root, & .MuiListItemText-primary": {
                color: "#ffffff",
              },
              "@media (max-width: 600px)": {
                width: "45%",
                fontSize: "1rem",
              },
            },
          }}
        >
          <List>
            <ListItem component={Link} to="/Projects" onClick={toggleHamburger}>
              <StyledListItemText primary="Portfolio" />
            </ListItem>
            <ListItem component={Link} to="/About" onClick={toggleHamburger}>
              <StyledListItemText primary="About" />
            </ListItem>
            <ListItem component={Link} to="/Contact" onClick={toggleHamburger}>
              <StyledListItemText primary="Contact" />
            </ListItem>
          </List>
        </Drawer>
      </div>
    </ThemeProvider>
  );

  return (
    <Navbar className="navbar" expand="xlg">
      <NavbarBrand className="navbar-brand" as={Link} to="/">
        <img className="navbar-brand-img" src={jAndD2} alt="Logo" />
      </NavbarBrand>
      {isMobile ? (<><NavHamburger toggleHamburger={toggleHamburger} isOpen={expanded} />
        <DrawerLinks /></>) : (<NavbarLinks />)}
    </Navbar>
  );
};

export default NaviBar;