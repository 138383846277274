import React, { useState, useEffect } from "react";
import ProjectSample from "../project_samples/ProjectSamples";

// import socialDev from "../image/socialDev.webp"
import xp1Webdev from "../image/xp1Webdev.webp"
// import satellite from '../image/satellite.webp'
import golista from '../image/golista.webp'
import inflow from '../image/inflow.png';
import awards from '../image/awards.png'
import shopRT from '../image/shopRT.webp'
import marketRT from '../image/marketRT.webp'
import xnana from '../image/xnana.webp'
import resumAgent from '../image/resumeAgentGif.png'
import financialAgent from '../image/financialAgent.png'

import Footer from "../components/footer/Footer";


const Projects = () => {
  console.log("Website developed by Julio Daniel using React, HTML, CSS and Javascript.")
  const [isMobile] = useState(window.innerWidth < 768);


  const projectData = [
    {
      title: "XNANA - Oaxacan Inspired Mexican Cuisine",
      description:
        "XNANA is a dynamic and interactive website inspired by the vibrant essence of Oaxacan culture. Built using ReactJS, HTML, and CSS, this site combines modern web technologies with creative design. A captivating background with smoky animated particles, evokes the warmth and mystery of traditional Oaxacan cuisine.",
      features: [
        "Responsive Design:<br /> A seamless user experience on both desktop and mobile, featuring smooth scrolling and a smoky particle immersive interface.",
        "Customized Typography:<br /> Unique font appearances achieved using advanced CSS `paint-order` properties to make text stand out.",
      ],
      technical: [
        "Interactive Background Animation:<br /> Leveraged a particle physics engine with React to create a smoky, animated atmosphere that enhances user engagement.",
        "Cross-Device Compatibility:<br /> Designed for flawless performance and responsiveness on all devices, from smartphones to desktops.",
      ],
      imageUrl: xnana,
      projectUrl: "https://xnana.vercel.app/",
    },
    {
      title: "Reena Tolentino - Art & Fashion E-Commerce Website",
      description:
        "Shop RT is an elegant e-commerce website created to showcase artist and fashion designer Reena Tolentino's unique creations. Shop RT delivers smooth interactivity, allowing visitors to explore her art and fashion collections effortlessly.",
      features: [
        "Secure Checkout:<br /> Enjoy a seamless, secure shopping experience with Stripe integration for fast and reliable payments.",
        "Responsive Design:<br /> Shop effortlessly from any device with a user-friendly, responsive interface built to adapt to any screen size.",
        "Curated Product Details:<br /> Each item features detailed descriptions and high-quality images, offering an immersive shopping experience.",
      ],
      technical: [
        "Custom API with Stripe Integration:<br /> Developed secure RESTful API routes hosted on a Node.js server, integrating Stripe to handle payment processing.",
        "Responsive Frontend Design:<br /> Utilized React to create a responsive, visually engaging user interface for a seamless shopping experience across devices.",
        "State Management with Redux:<br /> Implemented Redux to efficiently manage and synchronize global application state, ensuring smooth and responsive UI.",
      ],

      imageUrl: shopRT,
      projectUrl: "https://shop.reenatolentino.com/",
    },
    {
      title: "InFlow Invoice Tracking",
      description:
        "InFlow was developed using Next.js and PostgreSQL, and is designed to simplify your invoicing process, making it easier to manage your revenue and customer data effectively. Start streamlining your invoicing today with InFlow!",
      features: [
        "Revenue Summary:<br /> See the amounts for total collected, total pending.",
        "Add customers to your database:<br /> Create new customers in your database.",
        "Create Invoice Record:<br /> Assign invoices to the customers in your database.",
      ],
      technical: [
        "Developed with Next.js:<br /> Leverages built-in Next.js features like context() state management and auth() access management.",
        "Back-End:<br /> Using a PostgreSQL database and Next.js Server Actions to handle server-side logic.",
      ],
      imageUrl: inflow,
      projectUrl: "https://nextjs2-beta-jet.vercel.app/",
    },
    {
      title: "XP1: Your Ultimate Expense Tracking Companion",
      description:
        "XP1 is a powerful and user-friendly mobile app designed to help you take control of your finances. Developed using React Native, XP1 offers a seamless user experience and robust features to help you manage your spending like a pro.",
      features: [
        "Email Notifications:<br /> Integrated Nodemailer for sending custom-styled email notifications for new user registrations.",
        "Interactive Charts:<br /> Gain insights into your spending habits with intuitive and interactive charts that make understanding your finances a breeze.",
      ],
      technical: [
        "Node.js REST API:<br /> Developed RESTful API routes on a Heroku-hosted server, using Mongoose models for robust data validation.",
        "Responsive Design:<br /> Utilized React Native stylesheets to create a visually appealing and responsive app.",
      ],
      imageUrl: xp1Webdev,
      projectUrl: "https://apps.apple.com/us/app/xp1/id6470176329",
    },
    // {
    //   title: "Golista!",
    //   description:
    //     "Golista uses an advanced AI agent to generate ideas and kickstart your projects quickly. Whether you're setting personal goals, organizing tasks, or brainstorming new ventures, Golista provides personalized suggestions tailored to your needs. Start your journey with Golista today!",
    //   features: [
    //     "Goal-Based Idea Generation:<br /> Golista generates ideas to help you kickstart your projects quickly.",
    //     "ChatGPT Integration:<br /> The app uses advanced AI agents to provide specific and detailed suggestions tailored to your goals.",
    //     "User-Friendly Interface:<br /> Golista offers a clean and intuitive interface to ensure a smooth user experience.",
    //   ],
    //   technical: [
    //     "Back-End:<br /> Developed using Node.js, the backend serves as the intermediary between the front-end and external APIs. It manages user input, processes requests to OpenAI’s API, and formats the responses for the front-end.",
    //     "API Integration:<br /> Uses the OpenAI API to leverage the ChatGPT language model, enabling personalized suggestions and innovative idea generation based on user-provided data.",
    //   ],
    //   imageUrl: golista,
    //   projectUrl: "https://apps.apple.com/us/app/golista/id6450496916",
    // },
    {
      title: "Resume Agent",
      description:
        "Resume Agent is your go-to AI app for crafting exceptional resumes tailored to any job description. Leveraging the power of advanced AI, ResumAgent helps you stand out in the competitive job market.",
      features: [
        "AI-Driven Insights:<br /> CrewAI framework for orchestrating role-playing, autonomous AI agents, allows for dynamic and interactive resume-building sessions.",
        "Comprehensive Analysis:<br /> Revision and writing is performed by AI Agents. A report is generated having 'Revised Resume' and 'Summary of Changes'",
        "Adaptive Layout:<br /> Delivers a consistent and optimal user experience across all devices, from smartphones to tablets.",
      ],
      technical: [
        "Next.js/Typescript Front-End:<br /> The app features a Next.js Typescript frontend hosted on Vercel, ensuring a smooth and responsive user experience.",
        "Flask/Python Back-End:<br /> Powered by a Flask backend hosted on Heroku, ResumAgent efficiently handles and processes your requests.",
      ],
      imageUrl: resumAgent,
      projectUrl: "https://ai-research-agent.vercel.app/",
    },
    // {
    //   title: "Social Dev",
    //   description:
    //     "Join a powerful developer community designed to foster collaboration and knowledge-sharing! Share GitHub repositories, provide and receive detailed feedback, and dive deep into programming discussions. Take advantage of advanced features like repository commenting, project upvotes, and topic tagging to connect with like-minded professionals and enhance your development journey.",
    //   features: [
    //     "Profile Creation:<br /> Easily create and manage your developer profile, showcasing your skills and projects.",
    //     "Github Integraiton:<br /> Display your GitHub repositories directly on your profile, allowing others to view and access your work effortlessly.",
    //     "Post Sharing:<br /> Share posts and updates with the developer community, and stay engaged with the latest trends and discussions.",
    //   ],
    //   technical: [
    //     "State Management:<br /> Utilized Redux for efficient global state management, ensuring a responsive user interface.",
    //     "Front-End:<br /> Developed using JavaScript, HTML, and CSS with the React framework.",
    //     "Back-End:<br /> Built and maintained a robust Node.js server and REST API, enabling reliable CRUD operations on a MongoDB database through Mongoose models.",
    //   ],
    //   imageUrl: socialDev,
    //   projectUrl: "https://thesocialdev-21a01ec76f8c.herokuapp.com/",
    // },
    // {
    //   title: "Satellite Tracker",
    //   description:
    //     "This website tracks the International Space Station (ISS) in real-time. The application uses Flask for the front-end and Python for the back-end. By sending an API request and parsing the response data, it places the ISS icon on a map using its coordinates and displays its current velocity.",
    //   features: [
    //     "Real-Time Tracking:<br /> Continuously monitor the International Space Station's position with live updates.",
    //     "Velocity Display:<br /> See the current speed of the ISS displayed in real-time.",
    //     "Accurate Data:<br /> Integrates with a reliable API to provide precise and up-to-date tracking information.",
    //   ],
    //   technical: [
    //     "Coordinate Mapping:<br /> Utilizes a mapping library (such as Leaflet.js or Google Maps API) to plot the ISS's location on a map.",
    //     "Real-Time Data Updates:<br /> Employs periodic polling to keep the ISS position and velocity information current.",
    //   ],
    //   imageUrl: satellite,
    //   projectUrl: "https://whereissat-d141decca63a.herokuapp.com/",
    // },
  ];

  return (
    <>
      <div className="Content">
        {isMobile ? (
          <div className="lower-section">
            <div className="upper-section2">
              <img className="headshot" src={awards} alt={awards} />
              <h1 className="tossing">Julio Daniel</h1>
              <h2 className="job-title">
                Web and Mobile Apps Developer
              </h2>
            </div>
            {/* <section className="section-project-description"> */}
            <h3 className="project-description">
              I develop websites for desktop and mobile customized to your
              needs. Explore to see how I integrate the latest technology
              trends, ensuring creative, user-friendly solutions.
            </h3>
            {/* </section> */}
            <div className="lower-section2">
              {projectData.map((project, index) => (
                <ProjectSample key={index} project={project} />
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="lower-section">
              <div className="upper-section2">
                <img className="headshot" src={awards} alt={awards} />
                <h1 className="tossing">
                  Julio Daniel
                  <div className="marquee-container">
                    <h2 className="job-title">
                      Web and Mobile Apps Developer
                    </h2>
                  </div>
                </h1>
                {/* <h1 split-by="letter" letter-animation="breath">
                  Julio Daniel
                </h1> */}
              </div>
            </div>
            <div className="Content2">
              <div className="marquee-container">
                <section className="section-project-description">
                  <h3 className="project-description2">
                    I develop websites for desktop and mobile customized to your
                    needs. Explore to see how I integrate the latest technology
                    trends, ensuring creative, user-friendly solutions.
                  </h3>
                </section>
              </div>
            </div>
            <div className="lower-section2">
              {projectData.map((project, index) => (
                <ProjectSample index={index} key={index} project={project} />
              ))}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Projects;
